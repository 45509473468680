import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  Paper,
  Switch,
  TablePagination,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AdminService from "../services/AdminService";
import NavigationDrawer from "./NavigationDrawer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import usePagination from "../hooks/usePagination";

import { openInNewWindow } from "../tools/openInNewWindow";

import EditIcon from '@mui/icons-material/Edit';

const Products = ({ provider, hideMenu, isPersonalCard }) => {
  const [products, setProducts] = useState([]);
  const [countProducts, setCountProducts] = useState(0);
  const [loading, setLoading] = useState(false);
  const [freeText, setFreeText] = useState("");

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(0, 100);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - countProducts) : 0;

  const handleChangeActive = (active, prod) => {
    AdminService.updateProduct({ id: prod.id, active })
      .then((resp) => {
        setProducts((ps) => {
          const newPs = [...ps];
          const i = newPs.indexOf(prod);
          newPs[i] = { ...newPs[i], active };
          return newPs;
        });
      })
      .catch(console.log);
  };

  const handleChangeIsPublic = (isPublic, prod) => {
    AdminService.updateProduct({ id: prod.id, isPublic })
      .then((resp) => {
        setProducts((ps) => {
          const newPs = [...ps];
          const i = newPs.indexOf(prod);
          newPs[i] = { ...newPs[i], isPublic };
          return newPs;
        });
      })
      .catch(console.log);
  };

  const fetchProducts = () => {
    setLoading(true);
    AdminService.getProducts({
      pageNumber: page + 1,
      pageSize: rowsPerPage,
      providerId: provider?.id,
      isPersonalCard:isPersonalCard,
      freeText,
    })
      .then((resp) => {
        setProducts(resp?.data?.data || []);
        setCountProducts(resp?.data?.metadata?.count || 0);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line
  }, [page, rowsPerPage, freeText]);

  const openUserDetails = (userId) => {
    openInNewWindow(`/users/edit/${userId}`);
  };

  console.log("products", products);

  return (
    <Box>
      {!hideMenu && <NavigationDrawer />}
      <div style={{ marginLeft: hideMenu ? 0 : "195px", padding: 10 }}>
        <h1>
          {
            isPersonalCard
              ? "Tarjeta personal"
              : "Publicaciones"
          }
          {provider
            ? `de ${provider.name || provider.email} ${provider.lastName || ""}`
            : ""}
        </h1>
        <Divider sx={{ backgroundColor: "#4B599E",}}/>
        <Box>
          <TextField
            value={freeText}
            sx={{
              width: "100%",
              marginY: "20px",
            }}
            onChange={(e) => setFreeText(e.target.value)}
            placeholder="Buscar..."
          />
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TableContainer
                component={Paper}
                sx={{
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: "#F8F7F7",
                        //border: "1px solid black",
                      }}
                    >
                      <TableCell>ID</TableCell>
                      <TableCell>Titulo</TableCell>
                      <TableCell>Prestador</TableCell>
                      <TableCell>Categoria</TableCell>
                      <TableCell>Subcategoria</TableCell>
                      <TableCell>Precio</TableCell>
                      <TableCell>Listo para publicar?</TableCell>
                      <TableCell>Activo</TableCell>
                      <TableCell>Busqueda</TableCell>
                      <TableCell align="center">Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((p) => (
                      <TableRow key={p.id + "_provider"}>
                        <TableCell>#{p.id} </TableCell>
                        <TableCell>
                          <Link
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              goToPublication(p?.id, p?.user?.ownedStore?.code)
                            }
                          >
                            {p.title}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            href="#"
                            onClick={() => openUserDetails(p.user.id)}
                          >
                            {p.user?.email}
                          </Link>
                        </TableCell>
                        <TableCell align="center">
                          {p.category?.parent?.name || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {p.category?.name || "-"}
                        </TableCell>
                        <TableCell>
                          {p.price ? p.currency + " " + p.price : "Gratis"}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: p.readyToPublish ? "green" : "red",
                            fontWeight: "bold",
                          }}
                        >
                          {p.readyToPublish ? "SI" : "NO"}
                        </TableCell>
                        <TableCell>
                          <Switch
                            value={p.active}
                            checked={p.active}
                            onClick={(e) =>
                              handleChangeActive(e.target.checked, p)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            value={p.isPublic}
                            checked={p.isPublic}
                            onClick={(e) =>
                              handleChangeIsPublic(e.target.checked, p)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Box
                            display="flex"
                            width="100%"
                            justifyContent="space-evenly"
                          >
                            <Tooltip title="Editar" arrow>
                    
                              <IconButton
                                aria-label="edit"
                                style={{
                                  //backgroundColor: "gray",
                                  padding: 7,
                                }}
                                onClick={() =>
                                  openInNewWindow("/products/edit/" + p.id)
                                }
                              >
                                <EditIcon
                                 style={{ color: "#4B599E", fontSize: 22 }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={countProducts}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Box>
      </div>
    </Box>
  );
};

export default Products;

export const goToPublication = (productId, code) => {
  openInNewWindow(
    `${process.env.REACT_APP_WEB_APP_URL}/${code}/product/${productId}`
  );
};
