import {
  Box,
  CircularProgress,
  Link,
  Paper,
  TablePagination,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import AdminService from '../services/AdminService';
import NavigationDrawer from './NavigationDrawer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import usePagination from '../hooks/usePagination';
import { goToPublication } from './Products';
import { openInNewWindow } from '../tools/openInNewWindow';

const UserStatus = {
  CREATED : 'created',
  PARTIAL_PAID : "partial_paid",
  PAID : 'paid',
  CANCEL_REQUEST : "cancel_request",
  RESCHEDULE_REQUEST:"reschedule_request",
  CANCEL_PENDING : "cancel_pending",
  CANCEL_PAID : "cancel_paid",
  CANCELLED : 'cancelled',
  READY_FOR_REVIEW : 'ready_for_review',
  REVIEWED : 'reviewed'
};

const ProviderStatus = {
  CREATED: 'created',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};

const Subscriptions = () => {
  const [products, setSubscriptions] = useState([]);
  const [countProducts, setCountProducts] = useState(0)
  const [loading, setLoading] = useState(false);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(0, 100);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - countProducts) : 0;

  const fetchSubscriptions = () => {
    setLoading(true);
    AdminService.getSubscriptions({ pageNumber: page + 1, pageSize: rowsPerPage })
      .then((resp) => {
        setSubscriptions(resp?.data?.data || []);
        
        setCountProducts(resp?.data?.metadata?.count || 0)
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchSubscriptions();
    // eslint-disable-next-line
  }, [page, rowsPerPage]);

  const openUserDetails = (userId) => {
    openInNewWindow(`/users/edit/${userId}`);
  };

  const getDisplayClientStatus = (subs) => {
    // CANCELLED BY USER
    if (subs.userStatus === UserStatus.CANCELLED)
      return 'Cancelada-sin cargo';

    // PAYMENT PENDING
    if (
      subs?.event?.finalPrice !== 0 &&
      subs.userStatus === UserStatus.CREATED &&
      subs.providerStatus === ProviderStatus.ACCEPTED
    )
      return 'Pago pendiente';

      if (
        subs?.event?.finalPrice === 0 &&
        subs.userStatus === UserStatus.CREATED &&
        subs.providerStatus === ProviderStatus.ACCEPTED
      )
        return 'Gratis';



    // AWAITING PROVIDER RESPONSE
    if (
      subs.userStatus === UserStatus.CREATED &&
      subs.providerStatus === ProviderStatus.CREATED
    )
      return 'Esperando respuesta del prestador';

      if (
        subs.userStatus === UserStatus.PARTIAL_PAID)       
        return 'Pago parcial';

        if (
          subs.userStatus === UserStatus.READY_FOR_REVIEW)       
          return 'Feedback pendiente';

          if (
            subs.userStatus === UserStatus.REVIEWED)       
            return 'Dejó feedback';

      if (
        subs.userStatus === UserStatus.CANCEL_REQUEST)      
        return 'Solicitó cancelación';

      if (
        subs.userStatus === UserStatus.CANCEL_PENDING)      
        return 'Cancelada-pago pendiente';

      if (
        subs.userStatus === UserStatus.CANCEL_PAID)      
        return 'Cancelada-saldada';
      
      
        if (
          subs.userStatus === UserStatus.RESCHEDULE_REQUEST)      
          return 'Solicitó reprogramación';

          if (
            subs.userStatus === UserStatus.PAID)      
            return 'Pagada';

  };

  const getDisplayProviderStatus = (subs) => {
    // CANCELLED BY USER
    if (subs.providerStatus === ProviderStatus.ACCEPTED)
      return 'Aceptada';

    // REJECTED BY PROVIDER
    if (subs.providerStatus === ProviderStatus.CREATED)
      return 'Pendiente de aceptación';

      if (subs.providerStatus === ProviderStatus.REJECTED)
      return 'Rechazada por proveedor';
  };
// console.log("suscris",products)
  return (
    <Box>
      <NavigationDrawer />
      <div style={{ marginLeft: '195px', padding: 10 }}>
        <h1>Reservas</h1>
        <Box>
          {loading ? (
            <Box style={{width:"100%", height:"350px", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TableContainer component={Paper}>
                <Table >
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Servicio</TableCell>
                      <TableCell>Prestador</TableCell>
                      <TableCell>Usuario</TableCell>
                      <TableCell>Fecha Reservada</TableCell>
                      <TableCell>Creado</TableCell>
                      <TableCell>Precio</TableCell>
                      <TableCell>Estado Cliente</TableCell>
                      <TableCell>Estado Prestador</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((p) => (
                        <TableRow key={p.id + '_provider'}>
                          <TableCell>#{p.id}</TableCell>
                          <TableCell>
                            <Link
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                goToPublication(p?.event?.product?.id)
                              }
                            >
                              {p?.event?.product?.title}
                            </Link>
                          </TableCell>
                          <TableCell><Link href="#" onClick={() => openUserDetails(p.event?.user.id)} >{p.event?.user?.email}</Link></TableCell>
                          <TableCell><Link href="#" onClick={() => openUserDetails(p.user.id)} >{p.user?.email}</Link></TableCell>
                          <TableCell>{`${moment(p.event?.startTime).format(
                            'YYYY-MM-DD'
                          )} de ${moment(p?.event?.startTime).format(
                            'HH:mm'
                          )} a ${moment(p?.event?.endTime).format(
                            'HH:mm'
                          )}`}</TableCell>
                          <TableCell>
                            {moment(p?.createdDate).format('YYYY-MM-DD HH:mm')}
                          </TableCell>
                          <TableCell>
                            {p.event?.finalPrice
                              ? p.event?.currency + ' ' + p.event?.finalPrice
                              : 'Gratis'}
                          </TableCell>
                          <TableCell>{getDisplayClientStatus(p)}</TableCell>
                          <TableCell>{getDisplayProviderStatus(p)}</TableCell>
                        </TableRow>
                      ))}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component='div'
                count={countProducts}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Box>
      </div>
    </Box>
  );
};

export default Subscriptions;
